import React from 'react';
import styled from 'styled-components';

const Divider = (props) => <Line {...props} />;

export default Divider;

const Line = styled.div`
  border-top: 0.25rem solid #ffcd99;
  margin: 0.725rem 0;
  width: 6.4rem;
`;
