const MENU_DATA = {
  'apple-cobbler-cup': {
    title: 'Apple Cobbler Cup',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'apple-turnover': {
    title: 'Apple Turnover',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'apples-sunbutter': {
    title: 'Apples with Sunbutter',
    category: 'snack',
    glutenfree: true,
    vegetarian: true,
  },
  'bacon-ranch-salad': {
    title: 'Bacon Ranch Salad',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'baked-chicken-taco': {
    title: 'Baked Chicken Taco',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
    isNew: true,
  },
  'bbq-chicken-corn': {
    title: 'BBQ Chicken and Corn',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'bbq-chicken-salad': {
    title: 'BBQ Chicken Salad',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
    isNew: true,
  },
  'bean-cheese-enchilada': {
    title: 'Bean and Cheese Enchiladas',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'beef-chili-&-rice': {
    title: 'Beef Chili & Rice',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
    isNew: true,
  },
  'beef-chili-&-roll': {
    title: 'Beef Chili & Roll',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    isNew: true,
  },
  'beef-teryaki': {
    title: 'Beef Teriyaki with Rice',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'birthday-cake-protein-bites': {
    title: 'Birthday Cake Protein Bites',
    category: 'snack',
    glutenfree: true,
    vegetarian: true,
  },
  'broccoli-cheese-soup': {
    title: 'Broccoli Cheese Soup',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'burrito-bowl': {
    title: 'Burrito Bowl',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'caramel-apple-protein-bites': {
    title: 'Caramel Apple Protein Bites',
    category: 'snack',
    glutenfree: true,
    vegetarian: true,
  },
  'charcuterie-bistro-box': {
    title: 'Charcuterie Bistro Box',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    isNew: true,
  },
  'cheese-pizza-dippers-large': {
    title: 'Cheese Pizza Dippers Large',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
    size: 'large',
  },
  'cheese-ravioli': {
    title: 'Cheese Ravioli',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'cheesecake-+-blueberries': {
    title: 'Cheesecake + Blueberries',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'cheesy-bbq-chicken-sandwich': {
    title: 'Cheesy BBQ Chicken Sandwich',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'cheesy-pasta-spirals': {
    title: 'Cheesy Pasta Spirals',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'chef-salad': {
    title: 'Chef Salad',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'chefs-fried-noodles': {
    title: 'Chefs Fried Noodles',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'cherry-chocolate-chip-cookie': {
    title: 'Cherry Chocolate Chip Cookie',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
  },
  'chicken-adobe-&-rice': {
    title: 'Chicken Adobe & Rice',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
    isNew: true,
  },
  'chicken-caesar-wrap': {
    title: 'Chicken Caesar Wrap',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'chicken-fried-rice': {
    title: 'Chicken Fried Rice',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'chicken-korma-and-rice': {
    title: 'Chicken Korma and Rice',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'chicken-mash-bowl': {
    title: 'Chicken Mash Bowl',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'chicken-parm-and-pasta': {
    title: 'Chicken Parm and Pasta',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'chicken-pizza-bake': {
    title: 'Chicken Pizza Bake',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
    isNew: true,
  },
  'chicken-tenders-large': {
    title: 'Chicken Tenders (Large)',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    size: 'large',
  },
  'chicken-tenders-regular': {
    title: 'Chicken Tenders (Regular)',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    size: 'regular',
  },
  'chicken-tenders-small': {
    title: 'Chicken Tenders (Small)',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    size: 'small',
  },
  'chicken-teryaki-with-rice': {
    title: 'Chicken Teriyaki with Rice',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'chicken-tetrazzini': {
    title: 'Chicken Tetrazzini',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    isNew: true,
  },
  chili: {
    title: 'Chili',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'choc-swirl-cheesecake': {
    title: 'Choc Swirl Cheesecake',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'chocolate-cup-cake': {
    title: 'Chocolate "Cup" Cake',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'chocolate-chip-cookie': {
    title: 'Chocolate Chip Cookie',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
  },
  'chocolate-pudding-cup': {
    title: 'Chocolate Pudding Cup',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
  },
  'club-sandwich': {
    title: 'Club Sandwich',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    isNew: true,
  },
  'confetti-cup-cake': {
    title: 'Confetti "Cup" Cake',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'cookie-sandwich': {
    title: 'Cookie Sandwich',
    category: 'snack',
    glutenfree: false,
    vegetarian: false,
  },
  'corn-and-potato-chowder': {
    title: 'Corn and Potato Chowder',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'egg-ham-cheddar-puffs': {
    title: 'Egg Ham Cheddar Puffs',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    isNew: true,
  },
  'french-toast-bake': {
    title: 'French Toast Bake',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'fruit-smoothie': {
    title: 'Fruit Smoothie',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
  },
  'garden-salad': {
    title: 'Garden Salad',
    category: 'meal',
    glutenfree: true,
    vegetarian: true,
  },
  'garden-salad-with-chicken': {
    title: 'Garden Salad with Chicken',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'greek-salad': {
    title: 'Greek Salad',
    category: 'meal',
    glutenfree: true,
    vegetarian: true,
  },
  'ham-and-cheddar-sandwich': {
    title: 'Ham and Cheddar Sandwich',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    isNew: true,
  },
  'ham-cheddar-bites': {
    title: 'Ham Cheddar Bites',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'ham-swiss-sliders-and-sweet-potato-tots': {
    title: 'Ham Swiss sliders & Sweet Potato Tots',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'iced-butter-cookie': {
    title: 'Iced Butter Cookie',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
  },
  lasagna: {
    title: 'Lasagna',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'lemon-chicken-and-potatoes': {
    title: 'Lemon Chicken and Potatoes',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'mac-and-cheese': {
    title: 'Mac and Cheese',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'meat-lssagna': {
    title: 'Meat Lasagna',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'mediterranean-bowl': {
    title: 'Mediterranean Bowl',
    category: 'meal',
    glutenfree: true,
    vegetarian: true,
  },
  'mexi-bowl-with-chicken': {
    title: 'Mexi Bowl with Chicken',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'mini-chicken-tacos-+-rice': {
    title: 'Mini Chicken Tacos + Rice',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
    isNew: true,
  },
  napolean: {
    title: 'Napolean',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
    isNew: true,
  },
  'not-your-lunch-lady-bar': {
    title: 'Not Your Lunch Lady Bar',
    category: 'meal',
    glutenfree: true,
    vegetarian: true,
  },
  'orange-chicken-with-rice': {
    title: 'Orange Chicken with Rice',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'penne-alfredo': {
    title: 'Penne Alfredo',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'penne-alfredo-with-chicken': {
    title: 'Penne Alfredo with Chicken',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'pepperoni-pizza-sliders': {
    title: 'Pepperoni Pizza Sliders',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    isNew: true,
  },
  'pigs-in-blanket-with-baked-sp-fries': {
    title: 'Pigs in blanket with Baked SP Fries',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'rice-krispie-treat': {
    title: 'Rice Krispie Treat',
    category: 'snack',
    glutenfree: true,
    vegetarian: true,
  },
  'ricotta-bake': {
    title: 'Ricotta Bake',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'roast-beef-sliders-with-baked-sp-fries': {
    title: 'Roast Beef Sliders with Baked SP Fries',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'roasted-chicken-with-potato-wedges': {
    title: 'Roasted Chicken with Potato Wedges',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'snickerdoodle-cookie': {
    title: 'Snickerdoodle Cookie',
    category: 'snack',
    glutenfree: false,
    vegetarian: true,
  },
  'southwest-salad-with-chicken': {
    title: 'Southwest Salad with Chicken',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
  },
  'spaghetti-with-meatballs': {
    title: 'Spaghetti with Meatballs',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'steak-fajita-bowl': {
    title: 'Steak Fajita Bowl',
    category: 'meal',
    glutenfree: true,
    vegetarian: false,
    isNew: true,
  },
  'sweet-sour-meatballs-with-rice': {
    title: 'Sweet Sour Meatballs with Rice',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
  },
  'turkey-cheese-sandwich': {
    title: 'Turkey Cheese Sandwich',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    isNew: true,
  },
  'veg-lasagna': {
    title: 'Veg Lasagna',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'veggie-pita-with-hummus': {
    title: 'Veggie Pita with Hummus',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
  },
  'waffles-large': {
    title: 'Waffles (Large)',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
    size: 'large',
  },
  'waffles-regular': {
    title: 'Waffles',
    category: 'meal',
    glutenfree: false,
    vegetarian: true,
    size: 'regular',
  },
  'waffles-with-sausage-large': {
    title: 'Waffles with Sausage (Large)',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    size: 'large',
  },
  'waffles-with-sausage-regular': {
    title: 'Waffles with Sausage',
    category: 'meal',
    glutenfree: false,
    vegetarian: false,
    size: 'regular',
  },
  'yogurt-fruit-bistro': {
    title: 'Yogurt & Fruit Bistro Box',
    category: 'meal',
    glutenfree: true,
    vegetarian: true,
  },
  'yogurt-fruit-parfait': {
    title: 'Yogurt Fruit Parfait',
    category: 'snack',
    glutenfree: true,
    vegetarian: false,
  },
};

export default MENU_DATA;
